<template>
  <div class="infomation main_top" >
    <div class="container">
      <p><strong>playsavefristofficial.com</strong></p>
      <p><strong>{{ $t('key197') }}</strong></p>
      <p>{{ $t('key199') }}!</p>
      <p>
        {{ $t('key200') }}
        <strong>{{ $t('key201') }}</strong>.
      </p>
      <p>
        {{ $t('key202') }}
      </p>
      <p>{{ $t('key203') }}</p>
      <p><a href="#_heading=h.jy00mo2wjm0t">{{ $t('key204') }}</a></p>
      <ul>
        <li>
          <a href="#_heading=h.m745rc9wumn1">{{ $t('key205') }}</a>
        </li>
        <li>
          <a href="#_heading=h.onwcmvxptpxl">{{ $t('key206') }}</a>
        </li>
        <li>
          <a href="#_heading=h.onxiqxizng2l">{{ $t('key207') }}</a>
        </li>
      </ul>
      <p>
        <a href="#_heading=h.kcfsglqfbwni">{{ $t('key208') }}</a>
      </p>
      <ul>
        <li><a href="#_heading=h.1886mw3dn2ee">{{ $t('key209') }}</a></li>
        <li>
          <a href="#_heading=h.gqb0gk47zo0j">{{ $t('key210') }}</a>
        </li>
        <li>
          <a href="#_heading=h.wgp11yrepw2h">{{ $t('key211') }}</a>
        </li>
      </ul>
      <p>
        <a href="#_heading=h.s7wdnulbnvsv">{{ $t('key212') }}</a>
      </p>
      <p><a href="#_heading=h.r1nrl7luj1iv">{{ $t('key213') }}</a></p>
      <ul>
        <li>
          <a href="#_heading=h.uxb1sese2gjk">{{ $t('key214') }}</a>
        </li>
      </ul>
      <p><a href="#_heading=h.b1q120s7qjok">{{ $t('key215') }}</a></p>
      <h1><a id="_heading=h.jy00mo2wjm0t"></a>{{ $t('key204') }}</h1>
      <h2>
        <a id="_heading=h.m745rc9wumn1"></a><br><strong>{{ $t('key216') }}</strong>
      </h2>
      <p>
        {{ $t('key217') }}
      </p>
      <ul>
        <li>
          <strong>{{ $t('key218') }} </strong>{{ $t('key219') }}
          <ul>
            <li>
              {{ $t('key220') }}
            </li>
            <li>
              {{ $t('key221') }}
            </li>
            <li>
              {{ $t('key222') }}
            </li>
            <li>
              {{ $t('key223') }}
            </li>
            <li>
              {{ $t('key224') }}
            </li>
            <li>
              {{ $t('key225') }}
            </li>
            <li>
              {{ $t('key226') }}
            </li>
            <li>
              {{ $t('key227') }}
            </li>
          </ul>
        </li>
        <li>
          {{ $t('key228') }}
          <ul>
            <li>
              {{ $t('key229') }}
            </li>
            <li>
              {{ $t('key230') }}
            </li>
          </ul>
        </li>
        <li>
          {{ $t('key231') }}
          <ul>
            <li>
              {{ $t('key232') }}
            </li>
            <li>
              {{ $t('key233') }}
            </li>
            <li>
              {{ $t('key234') }}
            </li>
          </ul>
        </li>
      </ul>
      <p>
        {{ $t('key235') }}
      </p>
      <h2>
        <a id="_heading=h.onwcmvxptpxl"></a>{{ $t('key236') }}
      </h2>
      <p>
        {{ $t('key237') }}
      </p>
      <table>
        <tbody><tr>
          <td><p>{{ $t('key238') }}</p></td>
          <td><p>{{ $t('key239') }}</p></td>
          <td><p>{{ $t('key240') }}</p></td>
          <td><p>{{ $t('key241') }}</p></td>
        </tr>
        <tr>
          <td>
            <p>
              {{ $t('key242') }}
            </p>
          </td>
          <td>
            <p>{{ $t('key243') }}</p>
            <p>{{ $t('key244') }}</p>
            <p>{{ $t('key245') }}</p>
            <p>{{ $t('key246') }}</p>
            <p>{{ $t('key247') }}</p>
            <p>{{ $t('key248') }}</p>
            <p>{{ $t('key249') }}</p>
            <p>{{ $t('key250') }}</p>
            <p>{{ $t('key251') }}</p>
            <p>{{ $t('key252') }}</p>
            <p>{{ $t('key253') }}</p>
            <p>{{ $t('key254') }}</p>
            <p>{{ $t('key255') }}</p>
            <p>{{ $t('key256') }}</p>
          </td>
          <td>
            <p>{{ $t('key257') }}</p>
            <ul>
              <li>
                {{ $t('key258') }}
              </li>
              <li>
                {{ $t('key259') }}
              </li>
              <li>{{ $t('key260') }}</li>
              <li>{{ $t('key261') }}</li>
            </ul>
            <p>
              {{ $t('key262') }}
            </p>
            <ul>
              <li>{{ $t('key263') }}</li>
              <li>
                {{ $t('key264') }}
              </li>
              <li>{{ $t('key265') }}</li>
              <li>{{ $t('key266') }}</li>
              <li>{{ $t('key267') }}</li>
            </ul>
            <p>
              {{ $t('key268') }}
            </p>
            <ul>
              <li>
                {{ $t('key269') }}
              </li>
              <li>{{ $t('key270') }}</li>
              <li>{{ $t('key271') }}</li>
              <li>{{ $t('key272') }}</li>
            </ul>
            <p>
              {{ $t('key273') }}
            </p>
            <ul>
              <li>{{ $t('key274') }}</li>
              <li>{{ $t('key275') }}</li>
              <li>{{ $t('key276') }}</li>
              <li>{{ $t('key277') }}</li>
            </ul>
            <p>{{ $t('key278') }}</p>
            <ul>
              <li>{{ $t('key279') }}</li>
              <li>
                {{ $t('key280') }}
              </li>
            </ul>
          </td>
          <td>
            <p>{{ $t('key281') }}</p>
            <p>
              {{ $t('key282') }}
            </p>
            <p>{{ $t('key283') }}</p>
            <ul>
              <li>
                {{ $t('key284') }}
              </li>
              <li>
                {{ $t('key285') }}
              </li>
              <li>{{ $t('key286') }}</li>
              <li>{{ $t('key287') }}</li>
              <li>{{ $t('key288') }}</li>
            </ul>
            <p>
              {{ $t('key289') }}
            </p>
          </td>
        </tr>
        <tr>
          <td>
            <p>
              {{ $t('key290') }}
            </p>
          </td>
          <td>
            <p>{{ $t('key291') }}</p>
            <p>{{ $t('key292') }}</p>
            <p>{{ $t('key293') }}</p>
            <p>{{ $t('key294') }}</p>
            <p>{{ $t('key295') }}</p>
            <p>{{ $t('key296') }}</p>
            <p>{{ $t('key297') }}</p>
            <p>{{ $t('key298') }}</p>
          </td>
          <td>
            <p>
              {{ $t('key299') }}
            </p>
            <p>
              {{ $t('key300') }}
            </p>
            <ul>
              <li>{{ $t('key301') }}</li>
              <li>
                {{ $t('key302') }}
              </li>
            </ul>
          </td>
          <td>
            <p>{{ $t('key303') }}</p>
            <p>{{ $t('key304') }}</p>
            <p>
              {{ $t('key305') }}
            </p>
          </td>
        </tr>
        <tr>
          <td>
            <p>
              {{ $t('key306') }}
            </p>
          </td>
          <td>
            <p>{{ $t('key307') }}</p>
            <p>{{ $t('key308') }}</p>
            <p>{{ $t('key309') }}</p>
            <p>{{ $t('key310') }}</p>
            <p>{{ $t('key311') }}</p>
            <p>{{ $t('key312') }}</p>
            <p>{{ $t('key255') }}</p>
          </td>
          <td>
            <p>
              {{ $t('key313') }}
            </p>
            <p>
              {{ $t('key314') }}
            </p>
          </td>
          <td>
            <p>{{ $t('key315') }}</p>
            <p>{{ $t('key316') }}</p>
            <p>
              {{ $t('key317') }}
            </p>
            <p>{{ $t('key318') }}</p>
          </td>
        </tr>
        <tr>
          <td><p>{{ $t('key319') }}</p></td>
          <td><p>{{ $t('key320') }}</p></td>
          <td>
            <p>
              {{ $t('key321') }}
            </p>
          </td>
          <td>
            <p>{{ $t('key322') }}</p>
            <p>{{ $t('key323') }}</p>
          </td>
        </tr>
        <tr>
          <td><p>{{ $t('key324') }}</p></td>
          <td>
            <p>{{ $t('key325') }}</p>
            <p>{{ $t('key326') }}</p>
            <p>{{ $t('key327') }}</p>
            <p>{{ $t('key328') }}</p>
            <p>{{ $t('key329') }}</p>
            <p>{{ $t('key330') }}</p>
            <p>{{ $t('key331') }}</p>
            <p>{{ $t('key332') }}</p>
            <p>{{ $t('key333') }}</p>
            <p>{{ $t('key255') }}</p>
          </td>
          <td>
            <p>
              {{ $t('key334') }}
            </p>
            <ul>
              <li>
                {{ $t('key335') }}
              </li>
              <li>{{ $t('key336') }}</li>
              <li>{{ $t('key337') }}</li>
            </ul>
            <p>
              {{ $t('key338') }}
            </p>
          </td>
          <td>
            <p>{{ $t('key339') }}</p>
            <p>
              {{ $t('key340') }}
            </p>
            <p>
              {{ $t('key341') }}
            </p>
            <p>
              {{ $t('key342') }}
            </p>
          </td>
        </tr>
        <tr>
          <td>
            <p>
              {{ $t('key343') }}
            </p>
          </td>
          <td>
            <p>{{ $t('key344') }}</p>
            <p>{{ $t('key345') }}</p>
            <p>{{ $t('key346') }}</p>
            <p>{{ $t('key347') }}</p>
            <p>{{ $t('key348') }}</p>
            <p>{{ $t('key349') }}</p>
            <p>{{ $t('key350') }}</p>
            <p>{{ $t('key351') }}</p>
            <p>{{ $t('key352') }}</p>
            <p>{{ $t('key353') }}</p>
          </td>
          <td>
            <p>
              {{ $t('key354') }}
            </p>
            <p>
              {{ $t('key355') }}
            </p>
            <p>
              {{ $t('key356') }}
            </p>
          </td>
          <td>
            <p>{{ $t('key357') }}</p>
            <p>
              {{ $t('key358') }}
            </p>
          </td>
        </tr>
        <tr>
          <td>
            <p>
              {{ $t('key359') }}
            </p>
          </td>
          <td>
            <p>{{ $t('key360') }}</p>
            <p>{{ $t('key361') }}</p>
            <p>{{ $t('key362') }}</p>
            <p>{{ $t('key348') }}</p>
            <p>{{ $t('key349') }}</p>
            <p>{{ $t('key352') }}</p>
          </td>
          <td>
            <p>
              {{ $t('363') }}
            </p>
          </td>
          <td>
            <p>
              {{ $t('key364') }}
            </p>
          </td>
        </tr>
      </tbody></table>
      <p>
        {{ $t('key365') }}
      </p>
      <p>
        {{ $t('key366') }}
      </p>
      <h2>
        <a id="_heading=h.onxiqxizng2l"></a>{{ $t('key367') }}
      </h2>
      <p>
        {{ $t('key368') }}
      </p>
      <p>
        {{ $t('key369') }}
      </p>
      <ul>
        <li>
          {{ $t('key370') }}
        </li>
        <li>
          {{ $t('key371') }}
        </li>
        <li>
          {{ $t('key372') }}
        </li>
        <li>
          {{ $t('key373') }}
        </li>
        <li>
          {{ $t('key374') }}
        </li>
      </ul>
      <p>
        {{ $t('key375') }}
      </p>
      <p>
        {{ $t('key376') }}
      </p>
      <ul>
        <li>
          {{ $t('key377') }}
        </li>
      </ul>
      
      <ul>
        <li>
          {{ $t('key378') }}
        </li>
      </ul>
      <h1>
        <a id="_heading=h.kcfsglqfbwni"></a>{{ $t('key379') }}
      </h1>
      <h2><a id="_heading=h.1886mw3dn2ee"></a>{{ $t('key380') }}</h2>
      <p>
        {{ $t('key381') }}
      </p>
      <ul>
        <li>
          {{ $t('key382') }}
        </li>
        <li>{{ $t('key383') }}</li>
        <li>
          {{ $t('key384') }}
        </li>
        <li>
          {{ $t('key385') }}
        </li>
        <li>
          {{ $t('key386') }}
        </li>
        <li>
          {{ $t('key387') }}
          <ul>
            <li>
              {{ $t('key388') }}
            </li>
          </ul>
        </li>
      </ul>
      <p>
        {{ $t('key389') }}
      </p>
      <p>
        {{ $t('key390') }}
      </p>
      <p>
        {{ $t('key391') }}
      </p>
      <h2>
        <a id="_heading=h.gqb0gk47zo0j"></a>{{ $t('key392') }}
      </h2>
      <p>
        {{ $t('key393') }}
      </p>
      <p>
        {{ $t('key394') }}
      </p>
      <h2>
        <a id="_heading=h.wgp11yrepw2h"></a>{{ $t('key395') }}
      </h2>
      <p>
        {{ $t('key396') }}
      </p>
      <p>
        {{ $t('key397') }}
      </p>
      <h1>
        <a id="_heading=h.s7wdnulbnvsv"></a>{{ $t('key398') }}
      </h1>
      <p>
        {{ $t('key399') }}
      </p>
      <ul>
        <li>
          {{ $t('key400') }}
        </li>
        <li>
          {{ $t('key401') }}
        </li>
        <li>
          {{ $t('key402') }}
        </li>
        <li>
          {{ $t('key403') }}
        </li>
        <li>
          {{ $t('key404') }}
        </li>
      </ul>
      <p>
        {{ $t('key405') }}
      </p>
      <p>
        {{ $t('key406') }}
      </p>
      <p>
        {{ $t('key407') }}
      </p>
      <h1><a id="_heading=h.r1nrl7luj1iv"></a>{{ $t('key408') }}</h1>
      <h2>
        <a id="_heading=h.uxb1sese2gjk"></a>{{ $t('key409') }}
      </h2>
      <p>
        {{ $t('key410') }}
      </p>
      <p>
        {{ $t('key411') }}
      </p>
      <ul>
        <li>
          {{ $t('key412') }}
        </li>
        <li>
          {{ $t('key413') }}
        </li>
        <li>
          {{ $t('key414') }}
        </li>
        <li>
          {{ $t('key415') }}
        </li>
        <li>
          {{ $t('key416') }}
        </li>
        <li>
          {{ $t('key417') }}
        </li>
        <li>
          {{ $t('key418') }}
        </li>
        <li>
          {{ $t('key419') }}
        </li>
        <li>
          {{ $t('key420') }}
        </li>
      </ul>
      <p>
        {{ $t('key421') }}
      </p>
      <p>
        {{ $t('key422') }}
      </p>
      <ul>
        <li>
          {{ $t('key423') }}
        </li>
        <li>
          {{ $t('key424') }}
        </li>
        <li>
          {{ $t('key425') }}
        </li>
      </ul>
      <p>
        {{ $t('key426') }}
      </p>
      <p>
        {{ $t('key427') }}
      </p>
      <p>
        {{ $t('key428') }}
      </p>
      <p>
        {{ $t('key429') }}
      </p>
      <h1><a id="_heading=h.b1q120s7qjok"></a>{{ $t('key430') }}</h1>
      <p>
        {{ $t('key431') }}
      </p>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>